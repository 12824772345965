<template>
  <dashPageNew
      :title="entity.component_name || $t('Task')"
      :root="entity.course_name || $t('MyStudy')"
      :root-location="businessDashLink('courses/' + entity.course_id + '/student')"
      :subtitle="taskName"
      :icon="!SM ? taskIcon : 'mdi-chevron-left' "
      :header-route="SM ? businessDashLink('courses/' + entity.course_id + '/student') : null"
      :width="!SM ? '760px' : '100%'"
      side-user>

    <template #header_action.desktop>
<!--      <v-btn :to="businessDashLink('courses/' + entity.course_id + '/student')"  outlined class="noCaps" :color="wsATTENTION"> {{ $t('GoBack') }}</v-btn>-->
    </template>

    <template #default>

      <div class="mt-9 pb-16" v-if="displayEntity">


        <!-- Test Task Content -->
        <v-sheet v-if="entity.type === 'test' && entity.test_id"
                 :style="`border : 1px solid ${wsBACKGROUND}`"
                 class="wsRoundedLight pa-3  mt-6 mb-6" >

          <div class="d-flex justify-space-between">
            <div class="d-flex align-center">
              <v-icon class="mr-2">mdi-format-list-checks</v-icon>
              <h3 v-if="!SM" class="font-weight-medium">{{ $t('Test')}} <span class="font-weight-bold text-capitalize">{{ entity.test_name }}</span> </h3>
              <h4 v-if="SM" class="font-weight-medium">{{ $t('Test')}} <span class="font-weight-bold text-capitalize">{{ entity.test_name }}</span> </h4>
            </div>
            <div>
              <v-chip v-if="getSuccess()"   :color="wsSUCCESS">
                <h3 class="px-3">{{ $t('Success') }}</h3>
              </v-chip>
            </div>
          </div>


          <div v-if="!entity.test_unlimited && !getTestInProcess()" class="mt-3" style="width : 100%">
            <div class="d-flex " v-if="getStartNewTest()">
              <v-icon class="mr-2">mdi-reload</v-icon>
              <h3 v-if="!SM" class="font-weight-medium">{{ $t('TriesLeft')}}: <span class="font-weight-bold text-capitalize">{{ entity.test_tries }}</span> </h3>
              <h4 v-if="SM"  class="font-weight-medium">{{ $t('TriesLeft')}}: <span class="font-weight-bold text-capitalize">{{ entity.test_tries }}</span> </h4>
            </div>
            <h4 v-else class="font-weight-light">{{$t('TestNoMoreTries')}}</h4>
          </div>
          <div v-if="getTestInProcess()" class="mt-6">
            <h3 class="text-center">{{$t('TestInProcess')}}</h3>
          </div>
          <v-sheet  v-else-if="entity.student_test"
                    class="d-flex mt-6 wsRoundedHalf pa-3"
                    style="border: 1px solid white !important;"
                    color="transparent"  >
            <div style="width: 100%" >

              <div class="d-flex" v-if="entity.student_test.length > 1">
                <v-icon small class="mr-2">mdi-check</v-icon>
                <h4 class="font-weight-light">{{ $t('BestResult')}}:
                  <span class="font-weight-bold text-capitalize">{{ getBestTestResult() }}</span>
                </h4>
              </div>
              <div class="d-flex" :class="entity.student_test.length > 1 ? 'mt-3' : ''">
                <v-icon small class="mr-2">mdi-close-circle</v-icon>
                <h4 class="font-weight-light">{{ $t('WorstResult')}}:
                  <span class="font-weight-bold text-capitalize">
                  {{ getWorstTestResult() }}
                </span>
                </h4>
              </div>

            </div>

          </v-sheet>


          <v-btn v-if="getStartNewTest() || getTestInProcess()"
                 :to="businessDashLink('courses/test/' + entity.test_id)"
                 block large class="mt-6 noCaps"
                 :color="wsATTENTION"
                 href="entity.video_url"
                 dark>
            {{ getTestStartText() }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

        </v-sheet>
        <!-- Video Task Content -->
        <v-sheet
            v-if="entity.type === 'video'"
            :style="`border : 1px solid ${wsBACKGROUND}`"
            class="wsRoundedLight pa-3 my-6"
        >

          <div class="d-flex justify-space-between align-center">
            <div class="d-flex">
              <v-icon :color="wsATTENTION" class="mr-2">mdi-video</v-icon>
              <h3 class="font-weight-medium">{{ $t('VideoMeeting')}} </h3>
            </div>
            <h3 class="font-weight-medium">{{ getVideoTypeName(entity.video_type) }} </h3>
          </div>



          <v-text-field  @click:append="copy(entity.video_url)"
                         @click="$event.target.select()"
                         :value="entity.video_url"
                         :label="$t('Link')"
                         readonly
                         append-icon="mdi-content-copy"
                         prepend-inner-icon="mdi-link"

                         class="mt-9 " hide-details  outlined   />

          <v-text-field @click:append="copy(entity.video_password)"
                        @click="$event.target.select()"
                        :label="$t('Password')"
                        :value="entity.video_password"
                        append-icon="mdi-content-copy"
                        prepend-inner-icon="mdi-form-textbox-password"
                        class="mt-3 " hide-details readonly outlined />


            <div class="d-flex mt-6">
              <v-icon :color="wsACCENT" class="mr-2">mdi-calendar</v-icon>
              <h3 class="font-weight-medium">{{ $t('Date')}} : <span class="font-weight-bold text-capitalize">{{ entity.video_date }}</span> </h3>
            </div>


            <div class="d-flex">
              <v-icon :color="wsACCENT" class="mr-2">mdi-clock</v-icon>
              <h3 class="font-weight-medium">{{ $t('Time')}} : <span class="font-weight-bold text-capitalize">{{ entity.video_time }}</span> </h3>
            </div>




          <v-btn  :color="wsATTENTION" dark block large class="mt-9 mb-1 noCaps"
                 :href="entity.video_url" target="_blank" >
            {{ $t('Goto') }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

        </v-sheet>
        <!-- Task Description Content -->
        <div style="position:relative;">
          <wsTextViewer
              v-if="entity.description"
              :value="entity.description"
              :html="entity.text_description"
              :course-id="entity.course_id"
              student
              class="pb-6"
          />
        </div>
        <!-- OLD FILES Content -->
        <v-btn v-for="(file,i) in entity.files "
               @click="openFile(file )"
               :color="wsACCENT"
               class="noCaps ml-3 px-1"
               text
               :key="i + 'reply_file'"
        ><v-icon class="mr-3">mdi-paperclip</v-icon>
          {{ SHORTEN_FILE_NAME(file.name) }}
        </v-btn>

        <!-- STUDENT ANSWER -->
        <v-sheet v-if="entity.student_reply || entity.student_upload" min-height="200" :style="`border : 1px solid ${wsBACKGROUND}`"
                   class="wsRoundedLight mt-3 mb-9" >
            <div class="d-flex justify-space-between align-center pa-3">
              <h3 class="">{{ $t('Answer') }} </h3>

              <v-btn v-if="!entity.student_task_reply.is_published" @click="displayPublishReply" dark class="noCaps"  :color="wsATTENTION">
                {{ $t('SendAnswer') }}
              </v-btn>
              <h5 v-else :style="`color : ${wsACCENT}`">{{ $t('Published') }}</h5>
            </div>

            <v-divider :style="`border-color :  ${wsBACKGROUND}`"></v-divider>

            <ws-text-editor
                v-if="entity.student_task_reply && !entity.student_task_reply.is_published"
                v-model="entity.student_task_reply.content"
                @input="changeStudentReply"
                class="mb-6"
            />
            <ws-text-viewer
                v-if="entity.student_task_reply && entity.student_task_reply.is_published"
                v-model="entity.student_task_reply.content"
                class="pa-4"
            />

          <!-- OLD STUFF-->
          <div v-if="entity.student_upload && entity.type !== 'test' ">

            <template v-for="(file,i) in entity.reply_files "  >

              <v-hover v-slot="{hover}" :key="i + 'file_hover'">
                <div>
                  <v-btn
                      @click="openFile(file , true)"
                      :color="wsACCENT"
                      class="noCaps ml-3 px-1"
                      text
                      :key="i + 'file'">

                    <v-icon class="mr-3">mdi-paperclip</v-icon>

                    {{ SHORTEN_FILE_NAME(file.name) }}
                  </v-btn>
                  <v-btn
                      v-if="hover"
                      @click="deleteFile( file.uuid, i )"
                      :color="wsACCENT"
                      class="noCaps"
                      icon
                      :key="i + 'file_delete'">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>
              </v-hover>


            </template>

          </div>

          <div v-if="entity.teacher_reply">
            <v-divider :style="`border-color : ${wsBACKGROUND}`"></v-divider>
            <div class="d-flex justify-space-between align-center pa-3">
              <h3 class="">{{ $t('TeacherReply') }} </h3>
            </div>

            <v-divider :style="`border-color : ${wsBACKGROUND}`"></v-divider>
            <ws-text-viewer
                v-if="entity.student_task_reply "
                v-model="entity.teacher_reply"
                :course-id="entity.course_id"
                student
                class="pa-4"
            />

          </div>

          </v-sheet>

      </div>

      <div v-else>
        <h2 class="font-weight-light"> {{ $t('NoRightsToViewContent') }}</h2>
        <v-btn :to="businessDashLink('courses/student' )" outlined block  class="noCaps mr-3 mt-6" :color="wsACCENT">
          <v-icon>mdi-chevron-left</v-icon>
          {{ $t('BackToCourses') }}
        </v-btn>
      </div>

    </template>

    <template #dialog>

      <ws-dialog
          v-model="displayDialog"
          :title="$t('PublishStudentTaskReply')"
          @save="saveReply"
          :save-text="$t('Publish')"
          :cancel-text="$t('GoBack')"
          @cancel="displayDialog = false"
      >

        <h4 class="py-6 text-center">{{ $t('StudentTaskReplyConfirmationText') }}</h4>

      </ws-dialog>

      <ws-dialog  :title="$t('UploadFile')"
                  :save-text="$t('Yes')"
                  :show-save="false"
                  :cancel-text="$t('Finish')"
                  v-model="displayUploadFile">

        <h5 class="text-center font-weight-medium"> {{$t('UploadYourFilesInPdfDocFormat')}} : </h5>
        <wsImageUpload class="mt-6" @upload="uploadFile" />
      </ws-dialog>

      <!-- Display Photo Dialog-->
      <v-dialog v-model="displayImage"
                fullscreen>
        <v-sheet :color="wsBACKGROUND" class="fill-height">
          <div class="d-flex justify-space-between py-2 px-3 align-center">
            <h6>{{ selectedImage.name }}</h6>
            <div>
              <v-btn v-if="!SM" small :href="selectedImage.src" class="noCaps mr-3" :color="wsACCENT" outlined download type="attachment" target="_self">
                <v-icon small>mdi-download</v-icon>
                {{$t('Download')}}
              </v-btn>
              <v-btn :small="SM" icon @click="displayImage = false"><v-icon :small="SM" >mdi-close</v-icon></v-btn>
            </div>
          </div>
          <v-btn v-if="SM" block small :href="selectedImage.src" class="noCaps mb-3" :color="wsACCENT" text download type="attachment" target="_self">
            <v-icon small>mdi-download</v-icon>
            {{$t('Download')}}
          </v-btn>
          <v-img transition="xxx" :src="selectedImage.src" />
        </v-sheet>
      </v-dialog>

    </template>

  </dashPageNew>
</template>

<script>
import {mapActions} from "vuex";
import wsImageUpload from "@/components/UI/wsImageUpload";

export default {
  name: "studentTask",
  props : ['uuid'],
  components : {
    wsImageUpload
  },
  data() {
    return {
      displayDialog : false,
      displayEntity : true,
      displayImage : false,
      selectedImage : {
        src : '',
        name : ''
      },
      entity : {
        type : 'assignment',
        files : [],
        student_task_reply : {
          content : []
        }

      },
      // upload student reply
      displayUploadFile : false,
    }
  },
  computed : {
    taskIcon() {

      if ( this.entity.type === 'test' ) { return 'mdi-order-bool-ascending-variant' }
      if ( this.entity.type === 'video' ) { return 'mdi-cast-education' }
      return 'mdi-content-paste'

    },
    taskName() {

      if ( this.entity.type === 'test' ) { return this.$t('Test') }
      if ( this.entity.type === 'video' ) { return this.$t('VideoMeeting') }
      return this.$t('Task')

    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_TASK_STUDENT',
      'REMOVE_TASK_FILE',
      'ADD_EDIT_STUDENT_TASK_REPLY'
    ]),
    ...mapActions('upload', [
      'UPLOAD_STUDENT_REPLY',
      'GET_STUDENT_COMPONENT_FILE',
      'GET_STUDENT_REPLY_FILE_SELF',
    ]),

    async saveReply() {

      if (!this.entity.student_task_reply.content) {
        return this.notify(this.$t('PleaseEnterAnswer'))
      }
      if (this.entity.student_task_reply.content.length === 0) {
        return this.notify(this.$t('PleaseEnterAnswer'))
      }

      let data = {
        uuid : this.entity.student_task_reply.uuid || null,
        task_id : this.entity.uuid,
        content : this.entity.student_task_reply.content,
        is_published : true
      }

      let result = await this.ADD_EDIT_STUDENT_TASK_REPLY(data)
      if ( !result ) {
        return this.notify(this.$t('Error'))
      }
      this.entity.student_task_reply.uuid = result.uuid
      this.entity.student_task_reply.is_published = true
      this.notify(this.$t('StudentTaskReplyPublished'))
      this.displayDialog = false

    },
    async changeStudentReply($event) {

      let data = {
        uuid : this.entity.student_task_reply.uuid || null,
        task_id : this.entity.uuid,
        content : $event
      }
      let result = await this.ADD_EDIT_STUDENT_TASK_REPLY(data)
      if ( !result ) {
        return
      }
      if ( !this.entity.student_task_reply.uuid ) {
        this.entity.student_task_reply.uuid = result.uuid
      }
    },


    displayPublishReply() {
      this.displayDialog = true
    },


    async copy(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.notify(this.$t('CopiedToClipboard') )
      } catch($e) {
        this.notify(this.$t('Error') )
      }
    },
    async openFile(file , reply = false) {
      let data =  {
        file : file.uuid,
        course : this.entity.course_id
      }
      let result = !reply ? await this.GET_STUDENT_COMPONENT_FILE(data) : await this.GET_STUDENT_REPLY_FILE_SELF(data)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      if (result.mime.includes('image')) {
        this.displayImage = true
        this.selectedImage = {
          src : result.url,
          name : file.name
        }
      } else {
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

    },
    uploadFile(file) {

      let data = {
        file : file ,
        params : {
          entity : 'task',
          entity_id : this.uuid,
          create_entity : true,
          student_reply : true
        }

      }
      this.UPLOAD_STUDENT_REPLY(data).then((out)=>{
        if ( out.result ) {
          this.notify(this.$t('FileUploaded'))
          this.entity.reply_files.push(out.data.url)
          this.displayUploadFile = false
        }
        this.$store.state.dashboard.UPLOAD_LOADING = false
        this.loading = false
      }).catch(()=>{
        this.$store.state.dashboard.UPLOAD_LOADING = false
        this.loading = false
      })

    },
    deleteFile(uuid, index) {
      this.loading = true
      let data = {
        task: this.uuid,
        file : uuid
      }
      this.REMOVE_TASK_FILE(data).then((out)=>{
        if ( out.result ) {
          this.entity.reply_files.splice(index,1)
          this.notify(this.$t('FileDeleted'))
        }
        this.loading = false
      }).catch(()=> {
        this.loading = false
      })
    },

    //technical
    getTestInProcess() {
      if ( this.entity.student_test ) {
        let inProcess = this.entity.student_test.find(el => el.status === 'in_process')
        if ( inProcess ) {
          return true
        }
      }
      return false
    },
    getTestStartText() {
      if ( this.getTestInProcess() ) {
        return this.$t('Proceed')
      }
      return this.$t('StartTest')
    },
    getBestTestResult() {
      let items = this.entity.student_test.map( el => el.result )
      let sorted = items.sort( (a,b) => a < b )

      return sorted[0]
    },
    getWorstTestResult() {
      let items = this.entity.student_test.map( el => el.result )
      let sorted = items.sort( (a,b) => a > b )
      return sorted[0]
    },
    getTries() {
      if ( this.entity.student_test) {
        return this.entity.test_tries - this.entity.student_test.length
      }
      return this.entity.test_tries;
    },
    getStartNewTest() {
      if (this.entity.test_unlimited) { return true }
      if ( this.entity.test_tries === 0 ) { return false }
      return true;
    },
    getSuccess() {
      if ( this.entity.student_test) {
        if (  this.entity.student_test.length > 0 ) {
          let hasSuccess = this.entity.student_test.find(el=> el.status === 'success')
          if ( hasSuccess ) { return true }
        }
      }

      return false
    },
    showUploadFile() {
      this.displayUploadFile = true
    },

    getVideoTypeName(type) {
      let videoType = this.TASK_VIDEO_TYPES.find(el=>el.value === type)
      return videoType ? videoType.text : ''
    },

    async initPage() {
      this.selectedLang = this.$i18n.locale
      if ( !this.uuid ) {  return }

      let result = await this.GET_TASK_STUDENT(this.uuid);
      if ( !result ) {
        this.displayEntity = false
      }

      this.$store.state.tests.store_selectedTask = result.task.uuid
      if ( result.task.name ) {
        this.$store.state.tests.store_selectedTaskName = result.task.name[this.$i18n.locale]
      }

      this.entity = result.task

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>